<template>
	<div class="wrap" ondragstart="return false;" oncontextmenu="return false;">
		<!-- 头 -->
		<div class="head">
			<div style="cursor: pointer;">
				<img src="../assets/images/pc/logo.png" alt="" />
				<span>德语学习背单词</span>
			</div>
		</div>
		<div class="arrows" @click="gotoTop">
			<img src="../assets/images/pc/arrows.png" alt="" />
			<div>返回顶部</div>
		</div>
		<!--  下载 -->
		<div class="introduced">
			<div class="main">
				<div class="titleBox">
					<img src="../assets/images/pc/title.png" alt="" />
					<img src="../assets/images/pc/APP.png" alt="" />
					<div class="txt">每天十分钟，德语变轻松</div>
					<div class="downloadCode" v-show="appStoreCodeVisibl">
						<img
							src="../assets/images/pc/appDownloadCode.png"
							alt=""
						/>
						<div>扫描二维码下载</div>
					</div>
					<div
						class="downloadCode"
						style="left: 281px"
						v-show="androidCodeVisibl"
					>
						<img
							src="../assets/images/pc/appDownloadCode.png"
							alt=""
						/>
						<div>扫描二维码下载</div>
					</div>
					<div class="downloadBox">
						<div
							class="download"
							@mouseover="appStoreCodeVisibl = true"
							@mouseleave="appStoreCodeVisibl = false"
						>
							<img src="../assets/images/pc/iphone.png" alt="" />
							<div>AppStore下载</div>
						</div>
						<a
							href="https://ke.juquedu.com:8007/v3/api/version/germanword.apk"
							target="_blank"
						>
							<div
								class="download"
								@mouseover="androidCodeVisibl = true"
								@mouseleave="androidCodeVisibl = false"
							>
								<img
									src="../assets/images/pc/android.png"
									alt=""
								/>
								<div>Android下载</div>
							</div>
						</a>
					</div>
				</div>
				<img
					class="appDetails"
					src="../assets/images/pc/appDetails.png"
					alt=""
				/>
			</div>
		</div>
		<!-- 图片 -->
		<div class="detailsImg">
			<div @click="slide(1)">
				<img src="../assets/images/pc/details1.png" alt="" />
			</div>
			<div @click="slide(2)">
				<img src="../assets/images/pc/details2.png" alt="" />
			</div>
			<div @click="slide(3)">
				<img src="../assets/images/pc/details3.png" alt="" />
			</div>
			<div @click="slide(4)">
				<img src="../assets/images/pc/details4.png" alt="" />
			</div>
		</div>
		<!-- 内容 -->
		<div class="content">
			<div class="detailsBox" ref="teach">
				<div
					class="txtBox"
					v-if="teachTop"
					:class="teachTop ? 'Left' : ''"
				>
					<img src="../assets/images/pc/teach.png" alt="" />
					<div class="headline">精选词典 海量词汇</div>
					<div class="text">
						<div>精选德语词典</div>
						<div>名师精心录制</div>
						<div>分为八大词典</div>
						<div>单词数超过1万</div>
					</div>
				</div>
				<div
					class="imgBox"
					v-if="teachTop"
					:class="teachTop ? 'Right' : ''"
				>
					<img src="../assets/images/pc/circle1.png" alt="" />
					<img src="../assets/images/pc/icon1.png" alt="" />
					<img src="../assets/images/pc/courseList.png" alt="" />
				</div>
			</div>

			<div class="detailsBox" ref="practise">
				<div
					class="imgBox"
					v-if="practiseTop"
					:class="practiseTop ? 'Left' : ''"
				>
					<img src="../assets/images/pc/circle2.png" alt="" />
					<img src="../assets/images/pc/icon2.png" alt="" />
					<img src="../assets/images/pc/practise.png" alt="" />
				</div>
				<div
					class="txtBox"
					v-if="practiseTop"
					:class="practiseTop ? 'Right' : ''"
				>
					<img src="../assets/images/pc/practice.png" alt="" />
					<div class="headline">单词练习</div>
					<div class="text">
						<div>涵盖广A1到B2</div>
						<div>练听力</div>
						<div>练单词拼写</div>
						<div>练口语</div>
					</div>
				</div>
			</div>

			<div class="detailsBox" ref="evaluation">
				<div
					class="txtBox"
					v-if="evaluationTop"
					:class="evaluationTop ? 'Left' : ''"
				>
					<img src="../assets/images/pc/appraisal.png" alt="" />
					<div class="headline">练习评分</div>
					<div class="text">
						<div>练习评分</div>
						<div>自动判定用户对错</div>
						<div>系统立刻评判</div>
						<div>即时给出成绩</div>
					</div>
				</div>
				<div
					class="imgBox"
					v-if="evaluationTop"
					:class="evaluationTop ? 'Right' : ''"
				>
					<img src="../assets/images/pc/circle3.png" alt="" />
					<img src="../assets/images/pc/icon3.png" alt="" />
					<img src="../assets/images/pc/evaluation.png" alt="" />
				</div>
			</div>

			<div class="detailsBox" ref="test">
				<div
					class="imgBox"
					v-if="testTop"
					:class="testTop ? 'Left' : ''"
				>
					<img src="../assets/images/pc/circle4.png" alt="" />
					<img src="../assets/images/pc/icon4.png" alt="" />
					<img src="../assets/images/pc/testImg.png" alt="" />
				</div>
				<div
					class="txtBox"
					v-if="testTop"
					:class="testTop ? 'Right' : ''"
				>
					<img src="../assets/images/pc/test.png" alt="" />
					<div class="headline">测评练习</div>
					<div class="text">
						<div>先听音再朗读</div>
						<div>全对话模拟学习</div>
						<div>7种题型，十万种组合</div>
						<div>听力默写交汇学习，避免盲区</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer">
			<div class="footerContent">
				<div class="address">
					<div>
						Copyright © 2017-2023 长沙聚趣教育科技有限公司版权所有。
					</div>
					<div>
						<a href="http://beian.miit.gov.cn"
							>备案 : 湘ICP备2021002148号-1</a
						>
					</div>
					<div>
						公司地址 : 湖南省长沙市岳麓区麒云路九天银河产业园4栋7楼
					</div>
					<div>联系方式 : 400-862-8806</div>
				</div>
				<div class="codeBox">
					<div>
						<img src="../assets/images/pc/code2.png" alt="" />
						<div class="mintxt">公众号</div>
					</div>
					<div>
						<img src="../assets/images/pc/code.png" alt="" />
						<div class="mintxt">商务合作</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	components: {},
	name: 'Home',
	data() {
		return {
			appStoreCodeVisibl: false,
			androidCodeVisibl: false,
			teachTop: false,
			practiseTop: false,
			evaluationTop: false,
			testTop: false
		}
	},
	mounted() {
		window.addEventListener(
			'scroll',
			this.throttle(() => {
				let hgt = window.innerHeight
				let teachTop = this.$refs.teach.getBoundingClientRect().top
				let practiseTop = this.$refs.practise.getBoundingClientRect()
					.top
				let evaluationTop = this.$refs.evaluation.getBoundingClientRect()
					.top
				let testTop = this.$refs.test.getBoundingClientRect().top
				if (teachTop - hgt < -150) this.teachTop = true
				if (practiseTop - hgt < -150) this.practiseTop = true
				if (evaluationTop - hgt < -150) this.evaluationTop = true
				if (testTop - hgt < -150) this.testTop = true
			}, 100)
		)
	},
	methods: {
		gotoTop() {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			})
		},
		throttle(fn, wait) {
			var timer = null
			return function() {
				var context = this
				var args = arguments
				if (!timer) {
					timer = setTimeout(function() {
						fn.apply(context, args)
						timer = null
					}, wait)
				}
			}
		},
		slide(i) {
			switch (i) {
				case 1:
					window.scrollTo({
						top: this.$refs.teach.offsetTop,
						behavior: 'smooth'
					})
					break
				case 2:
					window.scrollTo({
						top: this.$refs.practise.offsetTop,
						behavior: 'smooth'
					})
					break
				case 3:
					window.scrollTo({
						top: this.$refs.evaluation.offsetTop,
						behavior: 'smooth'
					})
					break
				case 4:
					window.scrollTo({
						top: this.$refs.test.offsetTop,
						behavior: 'smooth'
					})
					break
			}
		}
	}
}
</script>
<style lang="scss" scoped>
a {
	color: #8c8c97;
	text-decoration: none;
}
.wrap {
	.head {
		width: 1200px;
		margin: 0 auto;
		height: 64px;
		display: flex;
		div {
			display: flex;
			align-items: center;
			img {
				width: 28px;
				height: 28px;
			}
			span {
				margin-left: 10px;
				font-size: 20px;
			}
		}
	}
	.arrows {
		cursor: pointer;
		z-index: 9999;
		width: 90px;
		height: 140px;
		background-color: rgba(240, 181, 178, 0.4);
		bottom: 0;
		right: 7px;
		position: fixed;
		color: #fff;
		font-size: 18px;
		font-weight: bold;
		img {
			display: block;
			margin: 0 auto;
			margin-top: 20px;
			width: 40px;
		}
		div {
			margin: 0 auto;
			margin-top: 10px;
			width: 40px;
			line-height: 25px;
		}
	}
	.introduced {
		background: url('../assets/images/pc/headBj.png');
		background-size: 100%;
		height: 650px;
		.main {
			height: 650px;
			width: 1200px;
			margin: 0 auto;
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
			.titleBox {
				width: 500px;
				position: relative;
				img {
					height: 104px;
				}
				.txt {
					font-size: 32px;
					color: #ffffff;
					text-align: center;
				}
				.downloadCode {
					position: absolute;
					left: 58px;
					top: 146px;
					width: 168px;
					height: 195px;
					background-color: #fff;
					text-align: center;
					font-size: 12px;
					color: #c9c9c9;
					line-height: 40px;
					img {
						margin-top: 10px;
						width: 140px;
						height: 140px;
					}
				}
				.downloadBox {
					// background-color: aquamarine;
					margin-top: 106px;
					width: 450px;
					display: flex;
					.download {
						cursor: pointer;
						margin: 0 auto;
						width: 168px;
						height: 60px;
						border-radius: 8px;
						background-color: #31160f;
						display: flex;
						align-items: center;
						justify-content: center;
						margin-bottom: 106px;
						img {
							height: 32px;
						}
						div {
							line-height: 25px;
							margin-left: 15px;
							width: 60px;
							color: #fff;
							font-size: 14px;
						}
					}
				}
			}
			.appDetails {
				width: 556px;
				height: 583px;
			}
		}
	}
	.detailsImg {
		display: flex;
		width: 100%;
		min-width: 1200px;
		padding: 14px 0;
		justify-content: center;
		box-sizing: border-box;
		div {
			position: relative;
			overflow: hidden;
			margin: 0 8px;
			border-radius: 4px;
		}
		img {
			max-width: 100%;
			transition: all 0.6s;
		}
		img:hover {
			cursor: pointer;
			transform: scale(1.1); /* 放大1.2倍 */
		}
	}
	.content {
		width: 1200px;
		margin: 0 auto;
		.detailsBox {
			margin: 100px 0;
			height: 700px;
			display: flex;
			align-items: center;
			justify-content: center;
			.Left {
				animation: fadeInLeft 0.5s ease-out 0.2s 1 both;
			}
			.Right {
				animation: fadeInRight 0.5s ease-out 0.2s 1 both;
			}
			.txtBox {
				// width: 500px;
				text-align: center;
				img {
					// display: block;
					// margin: 0 auto;
					width: 198px;
					height: 69px;
				}
				.headline {
					font-size: 30px;
					font-weight: bold;
				}
				.text {
					margin-top: 30px;
					font-size: 18px;
					color: #919191;
					div {
						margin: 10px;
					}
				}
			}
			.imgBox {
				height: 600px;
				padding-top: 100px;
				overflow: hidden;
				// background: #31160f;
				width: 600px;
				img {
					display: block;
					margin: 0 auto;
				}
				img:nth-child(1) {
					position: relative;
					width: 470px;
				}
				img:nth-child(2) {
					position: relative;
					left: 250px;
					top: -300px;
					width: 140px;
					z-index: 99;
				}
				img:nth-child(3) {
					top: -640px;
					position: relative;
					width: 298px;
					border-radius: 8px;
				}
			}
		}
	}
	.footer {
		background-color: #262636;
		height: 368px;
		display: flex;
		align-items: center;
		.footerContent {
			min-width: 1200px;
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: space-around;
			.address {
				font-size: 14px;
				color: #8c8c97;
				div {
					margin: 10px 0;
				}
			}
			.codeBox {
				display: flex;
				text-align: center;
				font-size: 18px;
				color: #8c8c97;
				img {
					height: 180px;
					width: 180px;
					margin-left: 20px;
				}
				.mintxt {
					margin-top: 20px;
				}
			}
		}
	}
}
</style>
